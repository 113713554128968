<template>
  <div>
    <hero-global title="Política de Privacidad "/>
    <div class="container">
      <section class="p-x-3-rem font-light">
        <p>Siguiendo los principios de licitud, lealtad y transparencia, y en cumplimiento de la normativa en materia de
          protección de datos personales, ponemos a su disposición la presente Política de Privacidad.</p>
      </section>

      <section class="p-3-rem font-light">
        <h2 class="subtitle font-size-24">¿Quién es el Responsable del tratamiento de sus datos?</h2>
        <article>
          <p><strong>RESPONSABLE</strong><br/>Comit&eacute; Espa&ntilde;ol de Representantes de Personas con
            Discapacidad<br/><br/><strong>NIF</strong><br/>G81639759<br/><br/><strong>DOMICILIO SOCIAL</strong><br/>C/
            Recoletos 1 &ndash; 28001, Madrid<br/><br/><strong>CONTACTO</strong><br/><strong>Email</strong>:
            gerencia@cermi.es<br/><strong>Tel&eacute;fono</strong>: 913601678</p>
        </article>
      </section>

      <section class="p-x-3-rem font-light">
        <h2 class="subtitle font-size-24">¿Con qué finalidad tratamos sus datos personales?</h2>
        <article>
          <p>
            En Comité Español de Representantes de Personas con Discapacidad, dependiendo de la categoría de interesado
            de que se trate, tratamos la información que nos facilita con las siguientes finalidades:
          </p>
        </article>
        <br/>
        <br/>
        <div>
          <table class="table stripped">
            <thead>
            <tr>
              <th><strong>INTERESADOS</strong></th>
              <th>FINALIDADES DEL TRATAMIENTO</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td><strong>POTENCIALES CLIENTES</strong></td>
              <td>Gestionar el alta de nuevos socios, gestionar el envío de información solicitada y/o resolver las
                consultas planteadas, facilitar ofertas de nuestros servicios y/o productos de su interés.
              </td>
            </tr>
            <tr>
              <td><strong>CLIENTES</strong></td>
              <td>Gestionar la relación comercial y/o profesional, facilitar ofertas de nuestros servicios y/o productos
                de su interés.
              </td>
            </tr>
            <tr>
              <td><strong>PROVEEDORES</strong></td>
              <td>Gestionar la relación comercial y/o profesional.</td>
            </tr>
            <tr>
              <td><strong>CANDIDATOS</strong></td>
              <td>Gestionar el proceso de selección de personal.</td>
            </tr>
            <tr>
              <td><strong>USUARIOS WEB</strong></td>
              <td>Gestionar el análisis de sus datos de navegación.</td>
            </tr>
            </tbody>
          </table>
        </div>
      </section>

      <section class="p-3-rem font-light">
        <h2 class="subtitle font-size-24">¿Cuál es la legitimación para el tratamiento de sus datos?</h2>
        <article>
          <p>La base legal para el tratamiento de sus datos personales, dependiendo de la categoría de interesado de que
            se trate, puede ser:</p>
        </article>
        <br/>
        <br/>
        <div>
          <table class="table stripped">
            <thead>
            <tr>
              <th><strong>INTERESADOS</strong></th>
              <th>BASE LEGAL</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td><strong>POTENCIALES CLIENTES</strong></td>
              <td>Art. 6.1.b RGPD: aplicación, a petición del interesado, de medidas precontractuales (presupuestos,
                hojas de encargo, ofertas de servicios…).
                Art. 6.1.f RGPD: Interés legítimo (enviar información solicitada, responder a las consultas
                planteadas…).
                Art. 6.1.a RGPD: consentimiento del propio interesado (facilitar ofertas de nuestros servicios y/o
                productos).
              </td>
            </tr>
            <tr>
              <td><strong>CLIENTES</strong></td>
              <td>Art. 6.1.b RGPD: ejecución de un contrato en el que el interesado es parte.</td>
            </tr>
            <tr>
              <td><strong>PROVEEDORES</strong></td>
              <td>Art. 6.1.b RGPD: ejecución de un contrato en el que el interesado es parte.</td>
            </tr>
            <tr>
              <td><strong>CANDIDATOS</strong></td>
              <td>Art. 6.1.a RGPD: consentimiento del propio interesado.</td>
            </tr>
            <tr>
              <td><strong>USUARIOS WEB</strong></td>
              <td>Art. 6.1.a RGPD: consentimiento del propio interesado.</td>
            </tr>
            </tbody>
          </table>
          <br/>
          <p>Los datos que le solicitamos son adecuados, pertinentes y estrictamente necesarios y en ningún caso está obligado a facilitárnoslos, pero su no comunicación podrá afectar a la finalidad del servicio o la imposibilidad de prestarlo. </p>
        </div>
      </section>

      <section class="p-x-3-rem font-light">
        <h2 class="subtitle font-size-24">¿Por cuánto tiempo conservaremos sus datos personales?</h2>
        <article>
          <p>Sus datos, serán conservados el tiempo mínimo necesario para la correcta prestación del servicio ofrecido así como para atender las responsabilidades que se pudieran derivar del mismo y de cualquier otra exigencia legal.</p>
        </article>
      </section>

      <section class="p-3-rem font-light">
        <h2 class="subtitle font-size-24">¿A qué destinatarios se comunicarán sus datos?</h2>
        <article>
          <p>Comité Español de Representantes de Personas con Discapacidad no comunicará sus datos a ningún tercero, salvo que se informe de ello expresamente.</p><br/>
          <br/>
          <p>Adicionalmente le informamos que determinados datos, en virtud de la normativa vigente o de la relación contractual que mantenga con Comité Español de Representantes de Personas con Discapacidad, podrán ser comunicados a:</p>
          <br/>
          <ul class="p-x-1-rem font-family-roboto">
            <li>Los bancos y entidades financieras para el cobro de los servicios contratados y/o productos comprados.</li>
            <li>Administraciones públicas con competencia en los sectores de la actividad de Comité Español de Representantes de Personas con Discapacidad, cuando así lo establezca la normativa vigente.</li>
          </ul>
        </article>
      </section>

      <section class="p-x-3-rem font-light">
        <h2 class="subtitle font-size-24">¿Cuáles son sus derechos cuando nos facilita sus datos?</h2>
        <article>
          <p>Los derechos de protección de datos que podrán ejercer los interesados, cuando procedan, son:</p><br/>
          <br/>
          <ul class="p-x-1-rem font-family-roboto">
            <li>Derecho a solicitar el acceso a los datos personales relativos al interesado.</li>
            <li>Derecho de rectificación o supresión.</li>
            <li>Derecho de oposición.</li>
            <li>Derecho a solicitar la limitación de su tratamiento.</li>
            <li>Derecho a la portabilidad de los datos.</li>
            <li>Derecho a no ser objeto de decisiones basadas únicamente en el tratamiento automatizado de datos.</li>
          </ul>
          <br/>
          <p>Los titulares de los datos personales obtenidos, podrán ejercer sus derechos de protección de datos personales dirigiendo una comunicación por escrito al domicilio social de Comité Español de Representantes de Personas con Discapacidad o al correo electrónico habilitado a tal efecto, <a class="text-primary" href="#">gerencia@cermi.es</a>.<br/><br/>

            Modelos, formularios y más información disponible sobre sus derechos en la página web de la autoridad de control nacional, Agencia Española de Protección de Datos, en adelante, AEPD, <a class="text-primary" title="Ir a página web www.aepd.es" href="www.aepd.es">www.aepd.es</a>	</p>
        </article>
      </section>

      <section class="p-3-rem font-light">
        <h2 class="subtitle font-size-24">¿Puedo retirar el consentimiento?</h2>
        <article>
          <p>Usted tiene la posibilidad y el derecho a retirar el consentimiento para cualquier finalidad específica otorgada en su momento, sin que ello afecte a la licitud del tratamiento basado en el consentimiento previo a su retirada.</p>
        </article>
      </section>

      <section class="p-x-3-rem font-light">
        <h2 class="subtitle font-size-24">¿Dónde puedo reclamar en caso de que considere que no se tratan mis datos correctamente?</h2>
        <article>
          <p>Si algún interesado considera que sus datos no son tratados correctamente por Comité Español de Representantes de Personas con Discapacidad o que las solicitudes de ejercicio de derechos no han sido atendidas de forma satisfactoria, puede interponer una reclamación ante a la autoridad de protección de datos que corresponda, siendo la AEPD la indicada en el territorio nacional, <a class="text-primary" href="www.aepd.es." title="Ir a página web www.aepd.es.">www.aepd.es.</a> </p>
        </article>
      </section>

      <section class="p-3-rem font-light">
        <h2 class="subtitle font-size-24">Seguridad y actualización de sus datos personales</h2>
        <article>
          <p>Con el objetivo de salvaguardar la seguridad de sus datos personales, le informamos que ha adoptado todas las medidas de índole técnica y organizativa necesarias para garantizar la seguridad de los datos personales suministrados. Todo ello para evitar su alteración, pérdida, y/o tratamientos o accesos no autorizados, tal como exige la normativa, si bien la seguridad absoluta no existe. </p><br/>
          <p>Es importante que, para que podamos mantener sus datos personales actualizados, nos informe siempre que se produzca una modificación de los mismos. </p>
        </article>
      </section>

      <section class="p-x-3-rem font-light">
        <h2 class="subtitle font-size-24">Confidencialidad</h2>
        <article>
          <p>Comité Español de Representantes de Personas con Discapacidad le informa que sus datos serán tratados con el máximo celo y confidencialidad por todo el personal que intervenga en cualquiera de las fases del tratamiento. No cederemos ni comunicaremos a ningún tercero sus datos, excepto en los casos legalmente previstos, o salvo que el interesado nos hubiera autorizado expresamente.</p>
        </article>
      </section>
    </div>
    <br/>
    <br/>
    <br/>
    <br/>
  </div>
</template>

<script>
import HeroGlobal from "@/components/Global/HeroGlobal";

export default {
  name: "PrivacyPolicyView",
  components: {HeroGlobal}
}
</script>
